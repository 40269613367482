<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition">
            <v-card class="elevation-1">
                <ValidationObserver ref="obsAlmacen" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="store">
                        <v-toolbar dark text color="primary">
                            <v-btn icon dark @click="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Registrar nuevo almacen</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon> Guardar
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Nombre almacen" prepend-icon="fas fa-pencil-alt"
                                        v-model="almacen.nombre" required :counter="100" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-autocomplete :items="empresas" item-text="empresa" item-value="id"
                                        v-model="almacen.empresaId" label="Empresa" required clearable prepend-icon="fas fa-building"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Direccion" prepend-icon="fas fa-location-arrow"
                                        required v-model="almacen.direccion" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <ValidationProvider v-slot="{ errors, valid }">
                                        <v-autocomplete :items="empleados" item-text="nombreCompleto" item-value="id"
                                        v-model="almacen.empleadoEncargadoId" label="Encargado" prepend-icon="fas fa-id-badge"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-checkbox v-model="almacen.activo" :hint="(almacen.activo == false ? 'Activar ':'Desactivar ') +'almacen en el sistema'"
                                    persistent-hint :label="'Almacen '+( almacen.activo == true ? 'Activa': 'Inactivo')">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import AlmacenService from '@/service/catalogo/almacen/almacenDataService';
export default {
    name: 'NuevoAlmacen',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        listaAlmacenes: { type: Function },
        empresas: { type: Array },
        empleados: { type: Array }
    },
    data() {
        return {
            almacen: {
                nombre: '',
                direccion: '',
                empresaId: null,
                usuarioEncargadoId: null,
                empleadoEncargadoId: null,
                activo: true,
                eliminado: false
            }
        }
    },
    methods: {

        store(){
            this.$refs.obsAlmacen.validate().then((isValid) => {
                if( isValid ){
                    AlmacenService.store(this.almacen).then( _ => {
                        this.$refs.obsAlmacen.reset();
                        this.listaAlmacenes();
                        this.handleModal();
                        this.$swal('Genial', "Almacen registrado", 'success', { timer: 1500 });
                    }).catch( err => {
                        this.$swal('Error!!', err.response.data, 'warning');
                    });
                }
            }).catch( err => {
                this.$swal('Error!!', err.response.data, 'warning');
            });
        }
    },
}
</script>