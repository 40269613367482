<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card>
                <ValidationObserver ref="obsEditAlmacen">
                    <v-form name="form" @submit.prevent="updateAlmacen">
                        <v-toolbar dark text color="primary">
                            <v-btn icon dark @click="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar información del almacén</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" text type="submit">
                                    Guardar cambios
                                    <v-icon class="ma-2" dense large>fas fa-save</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Nombre almacen" prepend-icon="fas fa-pencil-alt"
                                        v-model="dataAlmacen.nombre" required :counter="100" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-autocomplete :items="empresas" item-text="empresa" item-value="id"
                                        v-model="dataAlmacen.empresaId" label="Empresa" required clearable prepend-icon="fas fa-building"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                        <v-text-field label="Direccion" prepend-icon="fas fa-location-arrow"
                                        required v-model="dataAlmacen.direccion" :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <ValidationProvider rules="" v-slot="{ errors, valid}">
                                        <v-autocomplete :items="empleados" item-text="nombreCompleto" item-value="id"
                                        v-model="dataAlmacen.empleadoEncargadoId" label="Encargado" prepend-icon="fas fa-id-badge"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-checkbox v-model="dataAlmacen.activo" :hint="(dataAlmacen.activo == false ? 'Activar ':'Desactivar ') +'almacen en el sistema'"
                                    persistent-hint :label="'Almacen '+( dataAlmacen.activo == true ? 'Activa': 'Inactivo')">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import AlmacenService from '@/service/catalogo/almacen/almacenDataService';
export default {
    name: 'EditarAlmacen',
    props: {
        dialog: { type: Boolean, default: false },
        handleModal: { type: Function },
        listaAlmacenes: { type: Function },
        idAlmacen: { type: Number, required: true, default: 0 },
        empresas: { type: Array, required: true, default: [] },
        empleados: { type: Array, required: true, default: [] },
    },
    data(){
        return {
            dataAlmacen: []
        }
    },
    mounted(){
        this.getAlmacen();
    },
    methods: {
        getAlmacen(){
            AlmacenService.show(this.idAlmacen).then(result => {
                this.dataAlmacen = result.data;
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener datos del almacen', text: err.response.data.message });
            });
        },
        updateAlmacen(){
            this.$refs.obsEditAlmacen.validate().then((isValid) => {
                if( isValid ){
                    AlmacenService.update(this.dataAlmacen).then( resp => {
                        this.$refs.obsEditAlmacen.reset();
                        this.handleModal();
                        this.listaAlmacenes();
                        this.$swal.fire({ icon: 'success', title: 'Almacen actualizado', text: "El almacen se ha modificado exitosamente" });
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Error al modificar almacen', text: err.response.data.message });
                    });
                }
            }).catch(() => {
                this.$swal('Error', 'Ocurrio un error durante la edición, validar con un administrador', 'warning')
            });
        }
    }
}
</script>