import { http, authHeader } from '@/http-commons';

class EmpresaDataService {
    
    getallEmpresa( params){
        return http.get('api/v1/empresa/all', { params, headers : authHeader() });
    }
    create( data ){
        return http.post('api/v1/empresa/create', data, { headers : authHeader() });
    }
    
    show( id ){
        return http.get(`api/v1/empresa/show/${id}`, { headers : authHeader() });
    }
    lstEmpresa(){
        return http.get(`api/v1/empresa/lstEmpresa`, {headers: authHeader() });
    }
    update( data ){
        return http.put(`api/v1/empresa/update/${data.id}`, data, { headers: authHeader() });
    }
    delete( id ){
        return http.delete(`api/v1/empresa/delete/${id}`, { headers : authHeader() });
    }
}
export default new EmpresaDataService();