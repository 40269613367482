import { http, authHeader } from '@/http-commons';

class AlmacenDataService {
  store(data) {
    return http.post("api/v1/almacen/store", data, { headers: authHeader() });
  }
  show(id) {
    return http.get(`api/v1/almacen/show/${id}`, { headers: authHeader() });
  } 

  update(data) {
    return http.put(`api/v1/almacen/update/${data.id}`, data, { headers: authHeader() });
  }
  
  delete(id) {
    return http.delete(`api/v1/almacen/delete/${id}`, { headers: authHeader() });
  }
  getAll(){
    return http.get(`api/v1/almacen/getAll`, { headers: authHeader() });
  }
  listaAlmacen(params) {
    return http.get(`api/v1/almacen/listaAlmacen`, { params, headers: authHeader() });
  }
}
export default new AlmacenDataService();
