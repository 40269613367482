import { http, authHeader } from '@/http-commons';

class EmpleadoDataService {
    create( empleado ){
        return http.post('api/v1/empleado/create', empleado, { headers: authHeader() });
    }
    
    get( empleadoId ){
        return http.get(`api/v1/empleado/get/${empleadoId}`, { headers: authHeader() });
    }

    update( empleado ){
        return http.put(`api/v1/empleado/update/${empleado.id}`, empleado , { headers : authHeader() });
    }

    delete( empleadoId ){
        return http.delete(`api/v1/empleado/lock/${empleadoId}`, null, { headers : authHeader() });
    }

    all(){
        return http.get('api/v1/empleado/all', { headers: authHeader() });
    }

    allByCompany(empresaId){
        return http.get(`api/v1/empleado/allByCompany/${empresaId}`, { headers: authHeader() });
    }

    allDebtFree(){ 
        return http.get('api/v1/empleado/allDebtFree', { headers: authHeader() });
    }

    table( params ){
        return http.get('api/v1/empleado/table', { params, headers: authHeader() });
    }

    allWithoutAssistance() {
        return http.get('api/v1/empleado/allWithoutAssistance', { headers: authHeader() });
    }

}
export default new EmpleadoDataService();