<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">

            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo almacen
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar almacen" single-inline hide-details @keydown.enter="searchAlmacen">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                
                <v-data-table :headers="headers" :items="almacenes" class="elevation-1"
                sort-by="estatus" :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalAlmacen">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon @click="show(item)">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                        <v-btn icon @click="remove(item)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip :color="color(item.activo)" small label text-color="white">
                            <v-icon left color="white" small>fas fa-tag</v-icon>
                            {{ item.activo == true ? 'Activo' : 'Inactivo' }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <NuevoAlmacen :dialog.sync="dialog" :listaAlmacenes.sync="initAlmacen"
            :empresas.sync="empresas" :empleados.sync="empleados"
            :handleModal="handleModal" v-if="dialog">
            </NuevoAlmacen>
    
            <EditarAlmacen :dialog.sync="dialogEdit" :listaAlmacenes.sync="initAlmacen"
            :idAlmacen.sync="idAlmacen" :empresas.sync="empresas" :empleados.sync="empleados"
            :handleModal="handleEditModal" v-if="dialogEdit">
            </EditarAlmacen>
        </v-col>
    </v-row>
</template>

<script>
import AlmacenDataService from '@/service/catalogo/almacen/almacenDataService'
import EmpresaService from '@/service/catalogo/empresa/empresaDataService';
import EmpleadoService from '@/service/empleado/empleadoDataService';
import NuevoAlmacen from './NuevoAlmacen.vue';
import EditarAlmacen from './EditarAlmacen.vue'
import { getParamsPagination } from '@/utils/pagination';
export default {
    name: 'ListaAlmacen',
    components: { NuevoAlmacen, EditarAlmacen },
    created() {
        this.getEmpresa()
        this.getEmpleados()
        this.initAlmacen()
    },
    data() {
        return {
            empresas: [],
            empleados: [],
            almacenes: [],
            options: {},
            totalAlmacen: 0,
            loading: false,
            search: '',
            
            dialog : false,
            dialogEdit: false,
            idAlmacen: 0,
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false},
                { text: 'Almacen', value: 'nombre'},
                { text: 'Direccion', value: 'direccion'},
                { text: 'Empresa', value: 'empresa'},
                { text: 'Encargado', value: 'empleadoEncargado'},
                { text: 'Estatus', value: 'activo',},
                { text: 'Id', align :' d-none', sortable: false, value: 'id' , visible: false}
            ],
        }
    },
    methods: {
        async getEmpresa(){
            const { data } = await EmpresaService.lstEmpresa();
            this.empresas = data;
        },
        async getEmpleados(){
            const { data } = await EmpleadoService.all();
            this.empleados = data;
        },
        searchAlmacen(){
            this.options.page = 1;
            this.initAlmacen( this.options );
        },
        initAlmacen( options = {} ){
            this.loading = true;
            const params = getParamsPagination( { options, search: this.search } );
            AlmacenDataService.listaAlmacen( params ).then( resp => {
                const { rows, totalElements } = resp.data;
                this.almacenes = rows;
                this.totalAlmacen = totalElements;
            }).catch((err) => {
                this.$swal('Error', err.response.data, 'error');
            }).finally(() => {
                this.loading = false;
            })
        },
        show( item ){
            this.dialogEdit = true;
            this.idAlmacen = item.id;
        },
        remove( item ){
            const index = this.almacenes.indexOf(item);
            this.$swal({
                title: "Estas seguro?",
                text: "No se podra revertir la decision!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.isConfirmed) {
                    AlmacenDataService.delete(item.id).then( _ => {
                        this.almacenes.splice(index, 1);
                        this.$swal("Eliminado!", "El almacen fue eliminado.", "success");
                    }).catch( err => {
                        this.$swal('Validar', 'Almacen no eliminado', 'warning');
                    });
                }
            });
        },
        color( activo ){
            return ( activo ) ? 'teal lighten-2' : 'red darken-2'
        },
        fakeApiCall(){
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                let items = this.initAlmacen()
                const total = items.length
                if( sortBy.length === 1 && sortDesc.length === 1 ){
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]
                        
                        if( sortDesc[0]){
                            if( sortA < sortB) return 1
                            if( sortA > sortB) return -1
                            return 0
                        } else {
                            if( sortA < sortB) return -1
                            if( sortA > sortB) return 1
                            return 0
                        }
                    });
                }
                if( itemsPerPage > 0){
                    items = items.slice((page-1)* itemsPerPage, page * itemsPerPage)
                }
                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 1000);
            });
        },
        handleModal(){ this.dialog = !this.dialog },
        handleEditModal(){ this.dialogEdit = !this.dialogEdit }
        
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initAlmacen(val);
                }
            }, deep: true
        }
    },
}
</script>